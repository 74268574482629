import { ResultAsync } from 'neverthrow'

import type { Schemas } from '@fysioscout/fysioscout-js/type-helpers'

import { unwrap } from '@fysioscout/fysioscout-js/utils'
import { getErrorMessage } from '@fysioscout/utils/error'

import { fysioscoutBaseClient } from '@/lib/fysioscout'

/**
 * Refreshes the access token.
 *
 * @returns A promise that resolves to a boolean indicating whether the access token was
 *   successfully refreshed.
 */
export async function refreshAccessToken(): Promise<ResultAsync<Schemas['TokenResponse'], string>> {
  return ResultAsync.fromPromise(
    unwrap(fysioscoutBaseClient.POST('/api/auth/token/refresh')),
    getErrorMessage,
  )
}
