import type { Middleware } from 'openapi-fetch'

import { isAccessTokenExpired } from '@fysioscout/fysioscout-js/auth'
import { createClient } from '@fysioscout/fysioscout-js/client'
import { setDefaultHeaders, throwErrors } from '@fysioscout/fysioscout-js/middleware'

import { env } from '@/config/env'
import { refreshAccessToken } from '@/features/auth/auth'
import { getSession, removeSession, setSession } from '@/stores/auth-store'

/** Middleware function that sets the authentication headers in the request object. */
export const handleAuth = {
  async onRequest({ request }) {
    let jwt = getSession()

    /** If there's no JWT or the access token has expired, try to refresh */
    if (!jwt?.access_token || isAccessTokenExpired(jwt)) {
      const result = await refreshAccessToken()

      return result.match(
        (token) => {
          setSession(token)
          jwt = token
        },
        (err) => {
          removeSession()

          /** Throw an error to prevent the request from proceeding */
          throw err
        },
      )
    }

    /** Add the access token to the request */
    request.headers.set('Authorization', `Bearer ${jwt.access_token}`)

    return request
  },
} satisfies Middleware

const fysioscoutClient = createClient({
  baseUrl: env.VITE_API_BASE_URL,
  credentials: 'include',
})

fysioscoutClient.use(setDefaultHeaders)
fysioscoutClient.use(handleAuth)
fysioscoutClient.use(throwErrors)

const fysioscoutBaseClient = createClient({
  baseUrl: env.VITE_API_BASE_URL,
  credentials: 'include',
})

fysioscoutBaseClient.use(setDefaultHeaders)

export { fysioscoutBaseClient, fysioscoutClient }
