import React from 'react'

import type { SubmitForgottenPassword } from '@/features/auth/types/models'

import { Button } from '@fysioscout/ui/buttons'
import { Form, TextField } from '@fysioscout/ui/forms'

interface ForgottenPasswordFormProps {
  /** Optional CSS class for custom styling. */
  className?: string

  /** Indicates if the form submission is in progress. */
  isPending: boolean

  /** Function to handle the password reset submission. */
  submit: SubmitForgottenPassword
}

export function ForgottenPasswordForm(props: ForgottenPasswordFormProps) {
  const [email, setEmail] = React.useState('')

  return (
    <Form
      className={props.className}
      onSubmit={(e) => {
        e.preventDefault()
        props.submit({ email })
      }}
    >
      <TextField
        label={'Email'}
        name={'email'}
        type={'email'}
        isRequired
        value={email}
        onChange={setEmail}
      />

      <Button type={'submit'} isPending={props.isPending}>
        Send link til email
      </Button>
    </Form>
  )
}
