import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import { AppLayout } from '@/app/components/app-layout'
import { refreshAccessToken } from '@/features/auth/auth'
import { AuthProvider } from '@/features/auth/components/auth-provider'
import { fysioscoutBaseClient } from '@/lib/fysioscout'
import { setSession } from '@/stores/auth-store'

const SearchSchema = z.object({
  token_hash: z.string().optional().catch(''),
  verify_type: z
    .enum(['email', 'recovery', 'invite', 'email_change'] as const)
    .optional()
    .catch('email'),
})

export const Route = createFileRoute('/_auth')({
  validateSearch: (search) => SearchSchema.parse(search),
  beforeLoad: async ({ search: { token_hash, verify_type, redirect_to } }) => {
    const result = await refreshAccessToken()

    if (result.isOk()) {
      setSession(result.value)
      return
    }

    if (token_hash && verify_type) {
      const { data } = await fysioscoutBaseClient.POST('/api/auth/verify/token', {
        body: { token_hash, verify_type },
      })

      if (data) {
        return
      }
    }

    throw redirect({ to: '/login', search: { redirect_to } })
  },
  component: AuthRoute,
})

function AuthRoute() {
  return (
    <AuthProvider>
      <AppLayout />
    </AuthProvider>
  )
}
