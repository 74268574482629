import React from 'react'

import type { LoginBody } from '@/features/auth/api/use-login'

import { Button } from '@fysioscout/ui/buttons'
import { Form, TextField } from '@fysioscout/ui/forms'

interface LoginFormProps {
  /** Optional CSS class name for the form. */
  className?: string

  /** Indicates if the form submission is in progress. */
  isPending: boolean

  /** Function to handle the form submission. */
  submit: (params: LoginBody) => void
}

export function LoginForm(props: LoginFormProps) {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  return (
    <Form
      className={props.className}
      onSubmit={(e) => {
        e.preventDefault()
        props.submit({ email, password })
      }}
    >
      <TextField
        label={'Email'}
        name={'email'}
        type={'email'}
        isRequired
        value={email}
        onChange={setEmail}
      />

      <TextField
        label={'Password'}
        name={'password'}
        type={'password'}
        isRequired
        value={password}
        onChange={setPassword}
      />

      <Button type={'submit'} variant={'solid'} color={'accent'} isPending={props.isPending}>
        Log ind
      </Button>
    </Form>
  )
}
