import { LoaderIcon } from 'lucide-react'
import { tv } from 'tailwind-variants'

import type { VariantProps } from 'tailwind-variants'

const spinnerStyles = tv({
  base: 'inline-block size-[1em] origin-center animate-spin transition duration-1000 will-change-transform',
})

type SpinnerVariants = VariantProps<typeof spinnerStyles>

interface SpinnerOwnProps {
  className?: string
}

export interface SpinnerProps extends SpinnerOwnProps, SpinnerVariants {}

export function Spinner({ className }: SpinnerProps) {
  return <LoaderIcon className={spinnerStyles({ className })} />
}
