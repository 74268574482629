import { Heading as RACHeading } from 'react-aria-components'

import type { HeadingProps as RACHeadingProps } from 'react-aria-components'
import type { VariantProps } from 'tailwind-variants'

import { headingStyles } from './heading.styles'

type HeadingVariants = VariantProps<typeof headingStyles>

export interface HeadingProps extends RACHeadingProps, HeadingVariants {}

export function Heading({ className, level, size = '4', medium, ...rest }: HeadingProps) {
  return (
    <RACHeading
      level={level ?? 3}
      className={headingStyles({ size, medium, className })}
      {...rest}
    />
  )
}
