import React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { useTitle } from 'ahooks'
import { Link as RACLink } from 'react-aria-components'

import { toast } from '@fysioscout/ui/status'
import { Heading, Text } from '@fysioscout/ui/typography'
import { composeTailwindRenderProps, focusRing } from '@fysioscout/ui/utils'

import { useUpdateUser } from '@/features/auth/api/use-update-user'
import { ResetPasswordForm } from '@/features/auth/components/reset-password-form'

export const Route = createFileRoute('/_auth/reset-password')({
  component: ResetPasswordView,
})

function ResetPasswordView() {
  useTitle('Nulstil adgangskode')

  const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] = React.useState(false)

  const handleSuccessfulReset = () => {
    setIsPasswordResetSuccessful(true)
  }

  return (
    <div data-testid={'reset-password-view'}>
      {isPasswordResetSuccessful ? (
        <PasswordResetSuccess />
      ) : (
        <ResetPassword onSuccess={handleSuccessfulReset} />
      )}
    </div>
  )
}

function ResetPassword({ onSuccess }: { onSuccess?: () => void }) {
  const updateUserMutation = useUpdateUser()

  const formRef = React.useRef<HTMLFormElement>(null)

  const handleSubmit = (password: string) => {
    updateUserMutation.mutate(
      { password },
      {
        onSuccess: () => {
          formRef.current?.reset()

          toast.success('Din adgangskode er blevet opdateret.', {
            description: 'Du kan nu bruge din nye adgangskode til at logge ind igen.',
          })

          onSuccess?.()
        },
      },
    )
  }

  return (
    <div>
      <Heading size={'5'}>Ny adgangskode</Heading>

      <Text elementType={'p'} size={'2'} muted className={'mt-2'}>
        Indtast din nye adgangskode herunder.
      </Text>

      <ResetPasswordForm
        className={'mt-8'}
        submit={handleSubmit}
        isPending={updateUserMutation.isPending}
        isDisabled={updateUserMutation.isSuccess}
      />
    </div>
  )
}

function PasswordResetSuccess() {
  useTitle('Nulstil adgangskode')

  return (
    <div>
      <Heading size={'5'}>Din adgangskode er blevet nulstillet</Heading>

      <Text elementType={'p'} size={'2'} muted className={'mt-2'}>
        Du kan nu lukke vinduet og benytte din nye adgangskode til at logge ind i dit
        FysioScout-produkt igen.
        <br /> <br /> Hvis du har brug for hjælp, kan du kontakte os på{' '}
        <RACLink
          className={composeTailwindRenderProps(
            focusRing,
            'hover:text-accent-11 transition-colors',
          )}
          href={'mailto:service@fysioscout.dk'}
        >
          service@fysioscout.dk
        </RACLink>
        .
      </Text>
    </div>
  )
}
