import { useMutation } from '@tanstack/react-query'

import type { Options } from '@fysioscout/fysioscout-js/type-helpers'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutClient } from '@/lib/fysioscout'

export type UpdateUserOptions = Options<'put', '/api/user'>

export function useUpdateUser() {
  return useMutation({
    mutationFn: (body: UpdateUserOptions['body']) => {
      return unwrap(fysioscoutClient.PUT('/api/user', { body }))
    },
  })
}
