import React from 'react'
import {
  composeRenderProps,
  OverlayArrow as RACOverlayArrow,
  Popover as RACPopover,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type { PopoverProps as RACPopoverProps } from 'react-aria-components'

const popoverStyles = tv({
  base: 'bg-popover border-popover-border rounded-md border bg-clip-padding shadow-2xl',
  variants: {
    isEntering: {
      true: 'animate-in fade-in placement-bottom:slide-in-from-top-1 placement-top:slide-in-from-bottom-1 placement-left:slide-in-from-right-1 placement-right:slide-in-from-left-1 duration-150 ease-out',
    },
    isExiting: {
      true: 'animate-out fade-out placement-bottom:slide-out-to-top-1 placement-top:slide-out-to-bottom-1 placement-left:slide-out-to-right-1 placement-right:slide-out-to-left-1 duration-100 ease-in',
    },
  },
})

export interface PopoverProps extends Omit<RACPopoverProps, 'children'> {
  /** Determines whether an arrow should be displayed pointing to the origin element. */
  showArrow?: boolean

  /** Child nodes or elements to be rendered within the popover. */
  children: React.ReactNode
}

export function Popover({ children, showArrow, className, ...rest }: PopoverProps) {
  return (
    <RACPopover
      offset={showArrow ? 12 : 8}
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        popoverStyles({ ...renderProps, className }),
      )}
    >
      {showArrow && (
        <RACOverlayArrow className={'group'}>
          <svg
            width={12}
            height={12}
            viewBox={'0 0 12 12'}
            className={
              'group-placement-bottom:rotate-180 group-placement-left:-rotate-90 group-placement-right:rotate-90 block fill-white stroke-black/10 stroke-1'
            }
          >
            <path d={'M0 0 L6 6 L12 0'} />
          </svg>
        </RACOverlayArrow>
      )}
      {children}
    </RACPopover>
  )
}
