import { z } from 'zod'
import { fromZodError } from 'zod-validation-error'

/**
 * Converts a value to a boolean schema.
 *
 * @param defaultValue - The default value for the boolean schema. Defaults to false.
 * @returns The boolean schema.
 */
export function toBooleanSchema(defaultValue = false) {
  return z
    .enum(['true', 'false'])
    .default(defaultValue ? 'true' : 'false')
    .transform((val) => val === 'true')
}

export const envSchema = z.object({
  VITE_API_BASE_URL: z.string(),
  VITE_API_MOCKING: toBooleanSchema(true),
  VITE_AUTH_URL: z.string().url(),
  VITE_DISABLE_ANIMATIONS: toBooleanSchema(false),
  VITE_SENTRY_ENABLED: toBooleanSchema(false),
  VITE_SUPABASE_ANON_KEY: z.string().min(1),
  VITE_SUPABASE_BASE_URL: z.string().min(1),
  VITE_SUPABASE_ID: z.string().min(1),
})

/**
 * Parse the environment variable using envSchema.
 *
 * @param env - The environment variable to be parsed.
 * @returns - The parsed data from the environment variable.
 * @throws - If the parsing of the environment variable fails.
 */
export function parseEnv(env: unknown) {
  const parsed = envSchema.safeParse(env || process.env)

  if (!parsed.success) {
    throw new Error(fromZodError(parsed.error).message, { cause: parsed.error })
  }

  return parsed.data
}
