'use client'

import { MoonIcon, SunIcon } from 'lucide-react'
import { MenuTrigger, TooltipTrigger } from 'react-aria-components'
import { entries, keys } from 'remeda'

import type { ButtonProps } from '@fysioscout/ui/buttons'
import type { Theme } from '@fysioscout/utils/react'
import type { Selection } from 'react-aria-components'
import type { TitleCase } from 'string-ts'

import { Button } from '@fysioscout/ui/buttons'
import { Menu, MenuItem } from '@fysioscout/ui/collections'
import { Tooltip } from '@fysioscout/ui/overlays'

import { useThemeContext } from './theme-context'

const menuActions = {
  light: 'Light',
  dark: 'Dark',
  system: 'System',
} as const satisfies Record<Theme, TitleCase<Theme>>

const menuItems = entries(menuActions).map(([key, value]) => ({
  id: key,
  name: value,
}))

interface ThemeSelectorProps extends Pick<ButtonProps, 'size'> {
  className?: string
}

export function ThemeSelector({ size = 'sm', className }: ThemeSelectorProps) {
  const { isDarkMode, setTheme, theme } = useThemeContext()

  const handleSelectionChange = (key: Selection) => {
    if (key === 'all') return

    for (const action of keys(menuActions)) {
      if (key.has(action)) {
        setTheme(action)
      }
    }
  }

  const currentSelection = new Set([theme])

  return (
    <MenuTrigger>
      <TooltipTrigger>
        <Button
          variant={'ghost'}
          size={size}
          color={'neutral'}
          className={className}
          aria-label={'Skift farvetema'}
        >
          {isDarkMode ? <SunIcon /> : <MoonIcon />}
        </Button>

        <Tooltip>Skift farvetema</Tooltip>
      </TooltipTrigger>

      <Menu
        selectionMode={'single'}
        disallowEmptySelection
        selectedKeys={currentSelection}
        onSelectionChange={handleSelectionChange}
        items={menuItems}
      >
        {(item) => <MenuItem>{item.name}</MenuItem>}
      </Menu>
    </MenuTrigger>
  )
}
