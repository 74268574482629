import React from 'react'
import { useCookieState } from 'ahooks'
import { useMediaQuery } from 'usehooks-ts'

import { addTransitionBlockerStyle } from '../dom'

export type Theme = 'system' | 'light' | 'dark'

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)'
const COOKIE_KEY = 'fs-theme'

interface ThemeOptions {
  defaultValue?: Theme
  cookieKey?: string
  initializeWithValue?: boolean
}

interface ThemeReturn {
  isDarkMode: boolean
  setTheme: (theme: Theme) => void
  theme: Theme
  toggleTheme: () => void
}

export function useTheme({
  defaultValue = 'light',
  cookieKey = COOKIE_KEY,
  initializeWithValue = false,
}: ThemeOptions = {}): ThemeReturn {
  const isDarkOS = useMediaQuery(COLOR_SCHEME_QUERY, { initializeWithValue })
  const [theme, setTheme] = useCookieState(cookieKey, {
    defaultValue,
    path: '/',
    domain: process.env.NODE_ENV === 'production' ? '.fysioscout.com' : undefined,
  })

  const isDarkMode = React.useMemo(() => {
    return theme === 'dark' || (theme === 'system' && isDarkOS)
  }, [theme, isDarkOS])

  const toggleTheme = React.useCallback(() => {
    const modes: Theme[] = ['light', 'system', 'dark']

    setTheme((prevTheme) => {
      const nextIndex = (modes.indexOf(prevTheme as Theme) + 1) % modes.length
      return modes[nextIndex]
    })
  }, [setTheme])

  React.useEffect(() => {
    const applyTheme = () => {
      const removeTransitionBlocker = addTransitionBlockerStyle()
      const themeClassName = isDarkMode ? 'dark' : 'light'

      document.documentElement.classList.remove('dark', 'light')
      document.documentElement.classList.add(themeClassName)

      const themeColor = isDarkMode ? '#111111' : '#fcfcfc'
      let metaThemeColor = document.querySelector<HTMLMetaElement>('meta[name=theme-color]')

      if (!metaThemeColor) {
        metaThemeColor = document.createElement('meta')
        metaThemeColor.setAttribute('name', 'theme-color')
        document.head.append(metaThemeColor)
      }

      metaThemeColor.setAttribute('content', themeColor)

      setTimeout(removeTransitionBlocker, 150)
    }

    applyTheme()
  }, [isDarkMode])

  return {
    isDarkMode,
    setTheme,
    theme: theme as Theme,
    toggleTheme,
  }
}
