import { useMutation } from '@tanstack/react-query'

import type { Body } from '@fysioscout/fysioscout-js/type-helpers'

import { unwrap } from '@fysioscout/fysioscout-js/utils'
import { toast } from '@fysioscout/ui/status'

import { fysioscoutBaseClient } from '@/lib/fysioscout'

export type ResetPasswordBody = Body<'post', '/api/auth/reset-password'>

export function useResetPasswordForEmail() {
  return useMutation({
    mutationFn: async (body: ResetPasswordBody) => {
      return unwrap(fysioscoutBaseClient.POST('/api/auth/reset-password', { body }))
    },
    onSuccess: () => {
      return toast.success(
        'Vi har sendt dig en e-mail med et link til at nulstille din adgangskode.',
      )
    },
  })
}
