import { useMutation } from '@tanstack/react-query'

import type { Body } from '@fysioscout/fysioscout-js/type-helpers'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutBaseClient } from '@/lib/fysioscout'

export type VerifyOtpBody = Body<'post', '/api/auth/verify/otp'>

export function useVerifyOtp() {
  return useMutation({
    mutationFn: async (body: VerifyOtpBody) => {
      return unwrap(fysioscoutBaseClient.POST('/api/auth/verify/otp', { body }))
    },
  })
}
