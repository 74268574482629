import { useMutation } from '@tanstack/react-query'

import type { Body } from '@fysioscout/fysioscout-js/type-helpers'

import { unwrap } from '@fysioscout/fysioscout-js/utils'

import { fysioscoutBaseClient } from '@/lib/fysioscout'

export type LoginBody = Body<'post', '/api/auth/login'>

export function useLogin() {
  return useMutation({
    mutationFn: (body: LoginBody) => {
      return unwrap(fysioscoutBaseClient.POST('/api/auth/login', { body }))
    },
  })
}
