import React from 'react'
import { useLocale } from 'react-aria-components'

import type { VerifyOtpBody } from '@/features/auth/api/use-verify-otp'

import { Button } from '@fysioscout/ui/buttons'
import { Form, TextField } from '@fysioscout/ui/forms'

interface OtpFormProps {
  /** Optional class name for custom styling. */
  className?: string

  /** Function to handle OTP submission. */
  submit: (dto: Pick<VerifyOtpBody, 'token'>) => void

  /** Indicates if the form submission is in progress. */
  isPending: boolean
}

export function VerifyOtpForm(props: OtpFormProps) {
  const [token, setToken] = React.useState('')
  const { locale } = useLocale()

  return (
    <Form
      className={props.className}
      onSubmit={(e) => {
        e.preventDefault()
        props.submit({ token })
      }}
    >
      <TextField
        label={'Engangskode'}
        name={'token'}
        type={'text'}
        description={'Indtast den engangskode, du har modtaget på din e-mail.'}
        inputMode={'numeric'}
        isRequired
        minLength={6}
        maxLength={6}
        pattern={'[0-9]{6}'}
        autoFocus
        onChange={setToken}
        value={token}
        errorMessage={({ validationDetails }) => {
          const message =
            locale === 'da' ? 'Koden skal være 6 cifre.' : 'The code must be 6 digits.'

          return validationDetails.patternMismatch ? message : ''
        }}
      />

      <Button type={'submit'} className={'mt-2'} isPending={props.isPending}>
        Log ind
      </Button>
    </Form>
  )
}
