import React from 'react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'

import './index.css'

import { env } from '@/config/env'

import { App } from './app'

Sentry.init({
  dsn: 'https://19ac10376fb1866b6e3f08bda374d309@o4507457460633600.ingest.de.sentry.io/4507746627420240',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\.fysioscout\.com/,
    /^https:\/\/api-dev\.fysioscout\.com/,
  ],
  replaysSessionSampleRate: 0.25,
  replaysOnErrorSampleRate: 0.25,
  enabled: env.VITE_SENTRY_ENABLED,
})

async function enableMocking() {
  if (!env.VITE_API_MOCKING) return

  const { worker } = await import('@/app/mocks/browser')
  return worker.start({ onUnhandledRequest: 'bypass' })
}

void enableMocking().then(() => {
  const rootNode = document.querySelector('#root')

  if (!rootNode) {
    throw new Error('Root node not found')
  }

  ReactDOM.createRoot(rootNode).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  )
})
