'use client'

import React from 'react'
import { composeRenderProps, TextField as RACTextField } from 'react-aria-components'

import type {
  TextFieldProps as RACTextFieldProps,
  ValidationResult as RACValidationResult,
} from 'react-aria-components'

import { cn } from '../../utils'
import { Description, FieldError, Input, Label } from '../field'
import { fieldBorderStyles, fieldStyles } from '../field/field.styles'

export interface TextFieldProps extends RACTextFieldProps {
  label?: string
  hideLabel?: boolean
  description?: string | React.ReactNode
  errorMessage?: string | ((validation: RACValidationResult) => string) | React.ReactNode
  placeholder?: string
}

export const TextField = React.forwardRef<React.ElementRef<typeof Input>, TextFieldProps>(
  ({ label, description, errorMessage, className, ...rest }, ref) => (
    <RACTextField
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      {label && <Label className={cn({ 'sr-only': rest.hideLabel })}>{label}</Label>}

      <Input ref={ref} className={fieldBorderStyles} placeholder={rest.placeholder} />

      {description ? <Description>{description}</Description> : null}
      {!rest.isDisabled && <FieldError>{errorMessage}</FieldError>}
    </RACTextField>
  ),
)

TextField.displayName = 'TextField'
